.cw-chat-message {
    
    & > .cs-message__content-wrapper {
        & > .cs-message__footer {
            font-size: 0.75em;
            
            // This depend on $show-message-outgoing-sender-name scss flag
            // I don't need overwrite this flag for whole project so it's done only here
            & > .cs-message__sent-time,
            & > .cs-message__sender-name
            {
                display: block;
            }
            
        }
    }
    
    &__system {
        width:100%;
    }
    
    &-separator {
        margin-top:1rem;
        margin-bottom: 1rem;
    }
}

.cs-message-list__scroll-wrapper>.cs-message-group.cw-message-group__system {
    max-width: none;
    .cs-message-group__content {
        width: 100%;
        .cs-message__content-wrapper {
            width: 100%;
            .cs-message__content {
                background-color: #fff;
                text-align: center;
            }
        }
    }
}


